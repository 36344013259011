export default new Set([
  'children',
  'dangerouslySetInnerHTML',
  'key',
  'ref',
  'defaultValue',
  'defaultChecked',
  'innerHTML',
  'suppressContentEditableWarning',
  'suppressHydrationWarning',
  // deprecated
  'valueLink',
]);
